<template>
  <div class="page">
    <!--index.wxml-->
    <div class="empty_custom" style="padding-top:50px"></div>
    <div class="container haveBottomTabBar">
      <div class="login_page_notice">
        <!-- <div>本程序仅针对导游朋友开放</div> -->
        <div>请先登录/注册,进行身份验证</div>
      </div>
      <div class="login_inp_box" >
        <div class="sms_login_box" v-if="loginType == '1'">
          <div class="login_field">
			  <van-field
				  label="手机号"
				  v-model="phone"
				  data-name="phone"
				  clearable
				  placeholder="请输入手机号码"
			  />
		  </div>
		  <div class="login_field">
			  <van-field
				  label="验证码"
				  v-model="sms"
				  bindinput="bindInputData"
				  data-name="sms"
				  center
				  clearable
				  placeholder="请输入短信验证码"
				  :border="false"
				  use-button-slot>
				<van-button @click="sendPhoneCode" class="sms_send_btn" slot="button" size="small" type="defalut" :disabled="smsFlag">{{sendTime}}</van-button>
			  </van-field>
		  </div>
        </div>
        <div class="pwd_login_box" v-else-if="loginType == 2">
          <div class="login_field">
			  <van-field
				  label="账号"
				  v-model=" account"
				  bindinput="bindInputData"
				  data-name="account"
				  clearable
				  placeholder="请输入账号"
			  />
		  </div>
		  <div class="login_field">
			  <van-field
				  label="密码"
				  v-model="password"
				  type="password"
				  bindinput="bindInputData"
				  data-name="password"
				  placeholder="请输入密码"
				  :border="false"
				  custom-style="margin-bottom:50px;border-bottom:2px solid #eee;"
			  >
			  </van-field>
		  </div>
        </div>
        <div class="login_type_btn">
          <div v-if="loginType == '1'">
            <!-- <van-button class="change_login_type_btn" size="small" @click="changeLoginType">账户密码登录</van-button> -->
          </div>
          <div v-else>
            <!-- <van-button class="change_login_type_btn" size="small" @click="changeLoginType">验证码登录</van-button> -->
          </div>
        </div>
        <div class="login_btn_box">
          <div class="login_btn">
            <van-button class="go_login_btn" @click="submitLogin" type="info" block round>立即登录</van-button>
          </div>
          <div class="login_btn">
			  <UPButton class="go_unionpay_btn van-button"
			            @click="miniAuthLogin"
			            :scope="unionpayScope">
			    云闪付登录
			  </UPButton>
          </div>
          <div class="go_regist">
            <!-- <van-button
                custom-class="change_login_type_btn"
                size="small" plain block round
                @click="goRegPage"
            >
              还没账户，立即注册
            </van-button> -->
          </div>
        </div>
      </div>
    </div>
    <van-toast id="van-toast" />

  </div>
</template>

<script>
  export default {
    name: 'login',
    data() {
      return {
        loginType:2, //1 手机验证码登录  2 账号密码登录
        account:'',  //账号
        password:'', //密码
        phone:'', //手机号
        sms:'', //验证码
        smsFlag:false,
        sendTime: '发送验证码',
        sendColor: '#363636',
        snsMsgWait: 60,
        bottomTabBarActive:3, //底部导航位置
        wxCode:'',//授权code
        wxOpenId:'', //微信openid
        wxUserInfo:'',//微信用户信息
        canIUseGetUserProfile: false,
        showGetPhone:false,
		unionpayScope:"scope.mobile"
      }
    },
    components: {
		
    },
    created() {
		
    },
    methods: {
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail;
        let name = dataset.name;

        _this.data[name] = value;
        _this.name=_this.data[name]
      },
      //登录
      submitLogin:function(){

        let type = (this.loginType=='1')?1:0;
        let phone = this.phone;
        let sms = this.sms;
        let account = this.account;
        let password = this.password;
        //判断是否有推广人信息
        let spreadid = localStorage.getItem('spreadid');
        this.post(
          this.API.login,
          {
            type:type,
            phone:phone,
            smscode:sms,
            username:account,
            pwd:password,
            spreadid:spreadid
          }
        ).then(response => {
          if(response.err=='1'){
            this.$toast(response.msg)
          }else{
            let token = response.data.token;
            if(token!='') {
              localStorage.setItem('userToken',token);
              localStorage.setItem('user_id',response.data.user_id);
              localStorage.setItem('user_level',response.data.level);
              if(response.data.level=='3'){
                this.$router.replace({
                  name: 'Home',
                })
              }else{
                this.$router.go(-1)
              }

            }
          }
        })
      },
      //微信授权登录
      miniAuthLogin:function(event, err, result){
		
		if(err == null)
		{
			console.log("====code数据===");
			console.log(result.code);
			this.post(this.API.miniLogin,{code:result.code})
				.then(response => {
					if(response.err=='1'){
					  this.$toast(response.msg)
					}else{
					  let token = response.data.token;
					  if(token!='') {
					    localStorage.setItem('userToken',token);
					    localStorage.setItem('user_id',response.data.user_id);
					    localStorage.setItem('user_level',response.data.level);
					    if(response.data.level=='3'){
					      this.$router.replace({
					        name: 'Home',
					      })
					    }else{
					      this.$router.go(-1)
					    }
					
					  }
					}
				})
		}
		else
		{
			this.$toast("您拒绝了手机授权，可以使用其他方式登录");
		}
		
      },
      //发送验证码
      sendPhoneCode:function(){
        let phone = this.phone;
        console.log(phone);
        if(!(/^1[3456789]\d{9}$/.test(phone))){
          this.$toast('请填写正确的手机号码');
        }
        else
        {
          this.post(
            this.API.sendPhoneCode,
            {phone:phone})
			.then(response => {
			  console.log(response);
		  })

          var inter = setInterval(function() {
			  
              this.smsFlag = true;
              this.sendColor = '#cccccc';
              this.sendTime = this.snsMsgWait + 's后重发';
              this.snsMsgWait = this.snsMsgWait - 1;

            if (this.snsMsgWait < 0) {
              clearInterval(inter)
                this.sendColor = '#363636';
                this.sendTime = '发送验证码';
                this.snsMsgWait = 60;
                this.smsFlag = false;
            }
          }.bind(this), 1000);

        }
      },
      changeLoginType:function(){
        if(this.loginType==1)
        {
          this.loginType = 2 ;
        }
        else
        {
          this.loginType = 1 ;
        }
      },
      goBackPage:function() {
        wx.navigateBack({
          delta: 2,
        });
      },
      goRegPage:function(){
		this.$router.replace({
		  name: 'register',
		})
      }
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #FFFFFF;
  }
  .van-button {
	  font-size: 30px !important;
  }
  /* pages/login/index.wxss */
  :root {
    --button-baozi-background-color: #FFFFFF;
  }
  .login_inp_box {
    margin-top:100px;
    padding: 20px;
  }
  .login_inp_box .login_field {
	  margin-bottom:50px;
	  border-bottom:2px solid #ccc;
  }
  .login_type_btn {
    margin:-30px 20px;
    text-align: right;
    font-size:22px;
    color:#999;
  }
  .login_btn_box {
    margin-top:160px;
    padding:0px 50px;
  }
  .sms_send_btn {
    display: inline-block !important;
    color:#3089fd;
    background-color: transparent !important;
	font-size: 26px !important;
	padding:5px 10px !important;
	height: 46px;
	border:2px solid #3089fd;
  }
  .change_login_type_btn {
    color:#999 !important;
    border:none !important;
  }
  .login_btn {
    margin-bottom: 60px;
  }
  .login_btn .go_login_btn {
	  height: 84px !important;
  }
  .login_btn .go_unionpay_btn {
	  color:#07c160;
	  width: 100% !important;
	  height: 84px !important;
	  border:2px solid #07c160;
	  border-radius: 90px;
	  background-color: #FFFFFF !important;
  }
  .login_page_notice {
    margin-top:60px;
    padding:30px;
    font-size:38px;
    line-height: 68px;
    color:#999;
    text-align: center;
  }
  .dialog_msg {
    margin:40px 0px;
    color:#999;
    text-align: center;
    width: 100%;
    display: block;
  }

</style>
